/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities;

body {
  margin: 0;
}
* {
  box-sizing: border-box;
}

.flex {
  display: flex;
}
.flex.center {
  align-items: center;
  justify-content: center;
}
.flex.space-between {
  justify-content: space-between;
}
.flex.column {
  flex-direction: column;
}
.bg-white {
  background-color: white;
}
.padding-lg {
    padding: 24px;
}
.radius {
    border-radius: 6px;
}
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}
.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}